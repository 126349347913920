<!-- 设备管理 -->
<template>
    <div class="viewsPages">
        <div class="UserInfo-title">
            {{$t("userPage.text198")}}
            <span class="Devices-tips">{{$t("userPage.text199")}}</span>
        </div>
        <div class="views-Devices">
            <!-- 设备列表 -->
            <div class="Devices-list">
                <div class="device-item" v-for="item in systemDevices" :key="item.id">
                    <div>{{ item.lastLoginEquipment }}{{ item._source }}</div>
                    <div class="device-list__time">{{ item.thisMachine ? $t("userPage.text200") : ''}}</div>
                    <div class="device-list__time">ip:{{ item.sourceClientIp }}</div>
                    <div class="device-list__time">{{ item._updatedAt }}</div>
                    <div class="Devicesbtn">
                        <Button type="danger" @click="del(item.id)">{{$t("userPage.text201")}}</Button>
                    </div>
                </div>
            </div>
            <Empty v-if="!systemDevices.length" :description='$t("userPage.text202")' :image="require('@/assets/images/my/null.png')"></Empty>
        </div>
    </div>
</template>

<script>
import { _deviceList, _deviceListDel } from '../../../core/api/modules/system'
import { mapGetters } from 'vuex'
import { _jump } from '@/core/utils/utils'
import { Button, Empty } from 'element-ui'
export default {
    components: {
        Button,
        Empty,
    },
    created () {
        _deviceList()
    },
    computed: {
        ...mapGetters([
            'systemDevices'
        ])
    },
    methods: {
        _jump,
        // 删除
        del (id) {
            _deviceListDel(id)
        }
    }
}
</script>

<style scoped lang="scss">
.Devices-tips {
    font-size: 14px;
    vertical-align: bottom;
    margin-top: 10px;
}
.views-Devices{
    width: 890px;
    margin: 20px auto;
    padding: 20px 40px;
    border-radius: 10px;
    background:#DEDEDE;
    .Devices-list{
        margin-top: 40px;
        .device-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 15px auto;
            padding-bottom: 15px;
            border-bottom: 1px solid #E3E3E3;
            &>div{
                width: 20%;
            }
            .device-list__time{
                text-align: center;
            }
            .Devicesbtn{
                text-align: right;
            }
        }
    }
}
</style>